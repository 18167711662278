import {mapValues} from 'lodash';
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import {RetryLink} from '@apollo/client/link/retry';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {when} from '@mindfulness/utils/maybe';
import {isMobile} from 'react-device-detect';
import {captureException} from '@sentry/nextjs';

import {debug} from './logging';
import {getAttributionData, getAuthToken} from './auth';
import {isOnClient} from './next';
import {getSegmentAnonymousID} from './analytics';
import {buildReferrerUrl} from './url';

/* eslint-disable new-cap */
const retry502Link = new RetryLink({
  attempts: (count, _operation, error) => {
    console.info('Retry count:', count);
    return count < 3 && error.statusCode === 502;
  },
});

const authLink = setContext((op, {headers}) => {
  const token = getAuthToken();

  const noSession = [
    'redeemGiftCardAnonymously',
    'giftcardRegister',
    'subscribeAndRegister',
  ].includes(op.operationName || '');

  const thankYou = when(headers?.thankYou, (t) => t);
  const timeZone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/London';
  const referrerUrl = buildReferrerUrl(thankYou ?? window.location.href);

  return {
    headers: {
      ...headers,
      'client-platform': 'WEB',
      'client-version': process.env.WEB_VERSION,
      'mindfulness-space': process.env.MINDFULNESS_SPACE,
      'timezone': timeZone,
      'device-type': isMobile ? 'MOBILE_WEB' : 'WEB',
      ...(noSession ?
        {} :
        {
          // Add auth token when exists
          ...when(token, (t) => ({Authorization: `Bearer ${t}`})),
        }),
      ...when(getSegmentAnonymousID(), (id) => ({'segment-anonymous-id': id})),
      // Add segment anon-id to clientside request headers
      ...mapValues(getAttributionData(), encodeURI),
      ...when(referrerUrl, (url) => ({'referrer-url': url})),
    },
  };
});

const errorLink = onError((props) => {
  const {graphQLErrors, networkError, operation} = props;
  graphQLErrors?.forEach(({message, locations, path}) => {
    console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
        )}, Path: ${path}`,
    );
    console.debug(
        `[GraphQL debug]: ${operation.operationName} ${JSON.stringify(
            operation.variables,
        )}`,
    );
    // console.debug(
    //     `[GrapQL debug]: ${JSON.stringify(operation.query)} ${JSON.stringify(operation.variables)}`,
    // );
  });
  if (networkError) {
    console.error(
        `[Network error]: ${networkError.message}`,
        props.response?.errors,
        props.response?.data,
    );
    console.debug(`[Network data]: ${props.response?.data}`);
    console.debug(`[Network errors]: ${props.response?.errors}`);
  }
});

const clientErrorLink = onError((props) => {
  const {graphQLErrors, networkError, operation} = props;
  // Log network errors to console in dev
  if (networkError) {
    debug(networkError, {
      tags: {
        operation: operation.operationName,
      },
    });
  }
  if (graphQLErrors) {
    debug(graphQLErrors, {
      tags: {
        operation: operation.operationName,
      },
    });
    captureException({graphQLErrors, networkError, operation});
  }
});

const adminLink = createHttpLink({
  uri: `${process.env.API_PROTOCOL || 'https://'}${
    process.env.API_DOMAIN
  }/graphql`,
  headers: {
    'Authorization': `Bearer ${process.env.API_TOKEN}`,
    'client-platform': 'WEB',
    'client-version': process.env.WEB_VERSION as string,
    'mindfulness-space': process.env.MINDFULNESS_SPACE || 'mindfulness.com',
  },
});

const clientLink = createHttpLink({
  uri: `${process.env.API_PROTOCOL || 'https://'}${
    process.env.API_DOMAIN
  }/graphql`,
});

export const adminClient: ApolloClient<NormalizedCacheObject> =
  new ApolloClient({
    link: from([errorLink, retry502Link, adminLink]),
    cache: new InMemoryCache(),
  });

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([clientErrorLink, authLink, clientLink]),
  cache: new InMemoryCache(),
});

export const getGraphClient = () => {
  if (isOnClient()) {
    return client;
  }
  return adminClient;
};

/* eslint-enable new-cap */
